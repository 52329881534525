/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import CircularProcess from "@material-ui/core/CircularProgress";
import { ROLES } from "../../constants/constants";
import Alert from "@material-ui/lab/Alert";
import * as yup from "yup";
import { useSelector } from "react-redux";
import { Button, TextField, Typography } from "@material-ui/core";
import { getReportOrders } from "../../firebase/orders";
import { getController } from "../../logic/reducers";
import DynamicForm from "../Design/Form/DynamicForm";
import dateHelper from "../../helpers/dateHelper";
import Expandable from "../Design/Expandable";
import DataTable from "../Design/DataTable";
import isEmpty from "lodash/isEmpty";
import { INPUT_TYPE } from "../../constants/constants";
import circleService from "../../logic/services/circleService";
import moment from "moment";
import ReportTable from "./ReportTable";
import { orderStatus } from "../../constants/constants";
import BreadCrumb from "../../containers/navs/breadcrumb";

const EMPTY = {
  value: "ALL",
  text: "TODOS",
};

const headers = [
  {
    id: "orderId",
    name: "CODIGO",
  },
  {
    id: "name",
    name: "NOMBRE CLIENTE",
  },
  {
    id: "createdAt",
    name: "CARGA DE INFORMACIÓN",
  },
  {
    id: "assigned",
    name: "ASIGNACION DE WHEELER",
  },
  {
    id: "reciving",
    name: "ARRIBO A SUCURSAL",
  },
  {
    id: "onRute",
    name: "EN RUTA A CLIENTE",
  },
  {
    id: "deliveredStuff",
    name: "ENTREGA DE PEDIDO",
  },
  {
    id: "status",
    name: "Estatus",
  },
];

const formFields = [
  {
    label: "COMPAÑIA",
    autofocus: true,
    name: "company",
    cssClasses: "col-12 margin-top-md",
    fullWidth: true,
    inputType: INPUT_TYPE.FILTERABLE_LIST,
    options: [],
  },
  {
    label: "SUCURSAL",
    autofocus: true,
    name: "branch",
    cssClasses: "col-12 margin-top-md",
    fullWidth: true,
    inputType: INPUT_TYPE.FILTERABLE_LIST,
    options: [],
  },
  {
    label: "FECHA DE INICIO",
    autofocus: true,
    name: "startDate",
    cssClasses: "col-12 margin-top-md",
    fullWidth: true,
    inputType: INPUT_TYPE.FIELD,
    type: "date",
  },
  {
    label: "FECHA FINAL",
    autofocus: true,
    name: "endDate",
    cssClasses: "col-12 margin-top-md",
    fullWidth: true,
    inputType: INPUT_TYPE.FIELD,
    type: "date",
  },
];

const validationSchema = yup.object({});

const ReportView = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [filters, setFilters] = useState({
    company: EMPTY,
    branch: EMPTY,
    startDate: moment(new Date()).format("YYYY-MM-DD"),
    endDate: moment(new Date()).format("YYYY-MM-DD"),
  });
  const [lastDates, setLastDates] = useState({
    startDate: moment(new Date()).format("YYYY-MM-DD"),
    endDate: moment(new Date()).format("YYYY-MM-DD"),
  });
  const [customFormFields, setCustomFormFields] = useState(formFields);
  const controller = useSelector(getController);
  const [filterError, setFilterError] = useState("");
  const [data, setData] = useState([]);
  const [rows, setRows] = useState([]);
  const [circles, setCircles] = useState({});
  const [companies, setCompanies] = useState([]);
  const [branches, setBranches] = useState([]);

  const [btnList] = useState([
    {
      id: "apply-filters",
      type: "submit",
      variant: "contained",
      color: "primary",
      cssClasses: "display-inline-block padding-r-xsm margin-bottom-xsm",
      text: "aplicar filtros",
    },
  ]);

  const onCompanyChanged = (company, branches) => {
    if (!isEmpty(company) && company.value !== "ALL") {
      setCustomFormFields((fields) => {
        let newFields = [...fields];
        const branchIndex = newFields.findIndex((el) => el.name === "branch");
        newFields[branchIndex].options = branches.filter(
          (branch) => branch.companyId === company.value
        );
        return newFields;
      });
    } else {
      setCustomFormFields((fields) => {
        let newFields = [...fields];
        const branchIndex = newFields.findIndex((el) => el.name === "branch");
        newFields[branchIndex].options = branches;
        return newFields;
      });
    }
    setFilters((val) => ({
      ...val,
      branch: {},
    }));
  };
  const updateResults = async () => {
    let resData = [];
    setIsLoading(true);
    try {
      const startDate = new Date(`${filters.startDate} 00:00:00`);
      const endDate = new Date(`${filters.endDate} 23:59:59`);
      const res = await getReportOrders(
        controller.branchId,
        controller.companyId,
        startDate,
        endDate
      );
      resData = res;
      let companiesToAdd = [];
      let branchesToAdd = [];
      (res || []).forEach((element) => {
        const companyIndex = companiesToAdd.findIndex(
          (company) => company.value === element.company.id
        );
        if (companyIndex < 0) {
          companiesToAdd = [
            ...companiesToAdd,
            {
              value: element.company.id,
              text: element.company.name,
            },
          ];
        }
        const branchIndex = branchesToAdd.findIndex(
          (branch) => branch.value === element.branch.id
        );
        if (branchIndex < 0) {
          branchesToAdd = [
            ...branchesToAdd,
            {
              value: element.branch.id,
              text: element.branch.name,
              companyId: element.company.id,
            },
          ];
        }
      });
      setCompanies(companiesToAdd);
      setBranches(branchesToAdd);
      setCustomFormFields((fields) => {
        let newFields = [...fields];
        const companyIndex = newFields.findIndex((el) => el.name === "company");
        newFields[companyIndex].options = [EMPTY].concat(companiesToAdd);
        newFields[companyIndex].onValueChanged = (company) =>
          onCompanyChanged(company, branchesToAdd);
        const branchIndex = newFields.findIndex((el) => el.name === "branch");
        if (!isEmpty(filters.company) && filters.company !== EMPTY) {
          newFields[branchIndex].options = [EMPTY].concat(
            branchesToAdd.filter(
              (branch) => branch.companyId === filters.company.value
            )
          );
        } else {
          newFields[branchIndex].options = [EMPTY].concat(branchesToAdd);
        }
        return newFields;
      });
      const circlesToSet = {};
      (
        await Promise.all(
          companiesToAdd.map(async (company) =>
            circleService.getCirclesByCompanyId(company.value)
          )
        )
      ).forEach((circle) => {
        if (!isEmpty(circle)) {
          circlesToSet[circle[0].CompanyId] = circle;
        }
      });
      setCircles(circlesToSet);
      setData(res);
      setRows(res);
    } catch (e) {
      setHasError(true);
    }
    setIsLoading(false);
    return resData;
  };

  useEffect(() => {
    if (!isEmpty(controller)) {
      updateResults();
    }
  }, [controller]);

  const applyFilters = (currFilters, resData) => {
    if (
      !isEmpty(currFilters) &&
      (!isEmpty(currFilters.branch) || !isEmpty(currFilters.company))
    ) {
      let filteredResults = [];
      if (
        !isEmpty(currFilters.company) &&
        currFilters.company.value !== EMPTY.value
      ) {
        filteredResults = resData.filter(
          (val) => val.company.id === currFilters.company.value
        );
      } else {
        filteredResults = [...resData];
      }
      if (
        !isEmpty(currFilters.branch) &&
        currFilters.branch.value !== EMPTY.value
      ) {
        filteredResults = resData.filter(
          (val) => val.branch.id === currFilters.branch.value
        );
      }
      setRows(filteredResults);
    } else {
      setRows(resData);
    }
  };

  const getRows = () => {
    const currDate = moment(new Date()).format("DD-MM-YYYY");
    return rows
      .filter(
        (order) =>
          order.status !== orderStatus.RECEIVING &&
          order.status !== orderStatus.CANCELLED &&
          order.status !== orderStatus.GOING &&
          order.status !== orderStatus.ON_HOLD &&
          order.status !== orderStatus.RE_AGEND &&
          order.status !== orderStatus.DELETED &&
          order.status !== orderStatus.ASSIGNED &&
          order.status !== orderStatus.ACCEPTED
      )
      .map((row) => {
        let assigned = "";
        let reciving = "";
        let onRute = "";
        let deliveredStuff = "";
        let createdAt = "";
        let preparationTime = "";
        let deliveryTime = "";
        let deliveryTotalTime = "";
        let createdAtDate = "";
        let payMethod = row.payMethod;
        if (!isEmpty(row.createdAt)) {
          createdAt = moment(new Date(row.createdAt.seconds * 1000)).format(
            "HH:mm:ss"
          );
          createdAtDate = moment(new Date(row.createdAt.seconds * 1000)).format(
            "DD-MM-YYYY"
          );
        }
        if (!isEmpty(row.stateTime)) {
          if (!isEmpty(row.stateTime["Asignado"])) {
            assigned = moment(
              new Date(row.stateTime["Asignado"].seconds * 1000)
            ).format("DD-MM-YYYY HH:mm:ss");
          }
          if (!isEmpty(row.stateTime["Recibiendo"])) {
            reciving = moment(
              new Date(row.stateTime["Recibiendo"].seconds * 1000)
            ).format("DD-MM-YYYY HH:mm:ss");
          }
          if (!isEmpty(row.stateTime["En ruta"])) {
            onRute = moment(
              new Date(row.stateTime["En ruta"].seconds * 1000)
            ).format("DD-MM-YYYY HH:mm:ss");
          }
          if (!isEmpty(row.stateTime["Entregado"])) {
            deliveredStuff = moment(
              new Date(row.stateTime["Entregado"].seconds * 1000)
            ).format("DD-MM-YYYY HH:mm:ss");
          }
          if (
            !isEmpty(row.stateTime["Asignado"]) &&
            !isEmpty(row.stateTime["Entregado"])
          ) {
            deliveryTotalTime = dateHelper.secondsToHms(
              row.stateTime["Entregado"].seconds -
                row.stateTime["Asignado"].seconds
            );
          }
          if (
            !isEmpty(row.stateTime["En ruta"]) &&
            !isEmpty(row.stateTime["Entregado"])
          ) {
            deliveryTime = dateHelper.secondsToHms(
              row.stateTime["Entregado"].seconds -
                row.stateTime["En ruta"].seconds
            );
          }
          if (
            !isEmpty(row.stateTime["En ruta"]) &&
            !isEmpty(row.stateTime["Recibiendo"])
          ) {
            preparationTime = dateHelper.secondsToHms(
              row.stateTime["En ruta"].seconds -
                row.stateTime["Recibiendo"].seconds
            );
          }
        }

        const circle = row["circle"] || {};
        const distance =
          circle.distance !== "cancelado" ? circle.distance : "CANCELADO";
        const orderObservations = !isEmpty(row["orderObservations"])
          ? row["orderObservations"]
          : "NINGUNO";
        const circleId = circle.id;
        let circleValue = 0;
        if (!isEmpty(row.company)) {
          if (!isEmpty(circles[row.company.id])) {
            const circleToUse = circles[row.company.id][circleId - 1];
            if (!isEmpty(circleToUse)) {
              circleValue = circleToUse.price;
            }
          }
        }
        const wheeler = (row.alias || {}).name;
        const address = row.address;
        const nota = row.nota;
        return {
          ...row,
          currDate,
          createdAt,
          createdAtDate,
          payMethod,
          assigned,
          reciving,
          onRute,
          deliveredStuff,
          preparationTime,
          deliveryTime,
          deliveryTotalTime,
          distance,
          orderObservations,
          circleId,
          circleValue,
          wheeler,
          address,
          nota,
        };
      });
  };

  const onSubmit = async (values) => {
    let resData = [...data];
    setFilterError("");
    if (!isEmpty(values.startDate) && !isEmpty(values.endDate)) {
      const startDate = new Date(`${values.startDate} 00:00:00`);
      const endDate = new Date(`${values.endDate} 00:00:00`);
      if (startDate > endDate) {
        setFilterError("Fecha de inicio debe ser antes que la fecha final");
        return;
      }
    }
    if (
      values.startDate !== lastDates.startDate ||
      values.endDate !== lastDates.endDate
    ) {
      setLastDates({
        startDate: values.startDate,
        endDate: values.endDate,
      });
      resData = await updateResults();
    }
    applyFilters(values, resData);
  };

  const getText = (text) => {
    return `${text}`
      .replaceAll(",", "-")
      .replaceAll(";", "-")
      .replaceAll("#", "No.")
      .replaceAll("\n", "");
  };

  const getAllHeadres = () => [
    {
      id: "currDate",
      name: "FECHA",
    },
    ...headers,
    {
      id: "distance",
      name: "DISTANCIA",
    },
    {
      id: "orderObservations",
      name: "INCIDENTES",
    },
    {
      id: "circleId",
      name: "CIRCULO",
    },
    {
      id: "circleValue",
      name: "COSTO",
    },
    {
      id: "preparationTime",
      name: "TIEMPO DE PREPARACION",
    },
    {
      id: "deliveryTime",
      name: "TIEMPO DE ENTREGA",
    },
    {
      id: "deliveryTotalTime",
      name: "TIEMPO TOTAL DE ENTREGA",
    },
    {
      id: "wheeler",
      name: "WHEELER",
    },
    {
      id: "address",
      name: "DIRECCION",
    },
    {
      id: "nota",
      name: "CONTENIDO",
    },
    {
      id: "typeOrder",
      name: "TIPO DE ORDEN",
    },
  ];

  const generateCSV = () => {
    const rowsTouse = getRows();
    const allHeaders = getAllHeadres();
    const csvRows = rowsTouse.map((row) => {
      let data = [];
      allHeaders.forEach((header) => {
        Object.keys(row).forEach((key) => {
          if (header.id === key) {
            data = data.concat([getText(row[key])]);
          }
        });
      });
      return data;
    });
    const csvHeaders = allHeaders.map((header) => header.name);
    const csvInfo = [[...csvHeaders], ...csvRows];
    const csvContent =
      "data:text/csv;charset=utf-8," +
      csvInfo.map((e) => e.join(",")).join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "reporte.csv");
    link.click();
  };

  const ShowValueTextField = ({ value }) => {
    return (
      <TextField
        variant="outlined"
        value={value}
        inputProps={{
          readOnly: true,
          style: {
            textAlign: "center",
            boxSizing: "border-box",
          },
        }}
        label={"TOTAL DE ORDENES"}
        style={{ marginLeft: 5.5 }}
      />
    );
  };

  const renderView = () => {
    if (hasError) {
      return (
        <Alert severity="error">
          Se ha producido un error obteniendo la información, por favor vuelva a
          intentarlo más tarde!
        </Alert>
      );
    }

    //console.log(controller.role)

    return (
      <>
        {!isEmpty(filterError) && (
          <>
            <Alert severity="error">{filterError}</Alert>
            <br />
          </>
        )}
        <div>
          <BreadCrumb match={{ path: "" }} title="REPORTE DE ORDENES" />
          {/* <Button
            size="small"
            type="button"
            variant="outlined"
            color="secondary"
            onClick={generateCSV}
          >
            Descargar CSV
          </Button> */}
        </div>
        {controller.role === ROLES.OWNER ||
        controller.role === ROLES.CONTROLLER ? (
          // <Expandable title="Filtros">
          <DynamicForm
            obj={filters}
            setState={setFilters}
            fields={customFormFields}
            schema={validationSchema}
            buttons={btnList}
            onSubmit={onSubmit}
            btnAlignment="right"
            forceSetState
          />
        ) : (
          //</Expandable>
          <DynamicForm
            obj={filters}
            setState={setFilters}
            fields={customFormFields}
            schema={validationSchema}
            buttons={btnList}
            onSubmit={onSubmit}
            btnAlignment="right"
            forceSetState
          />
        )}
        <br />
        {/* <DataTable
          usePagination
          pageSize={10}
          align="left"
          headers={headers}
          rows={getRows()}
        /> */}

        {/* REPORTE DE ORDENES */}
        {/* <Typography
          style={{
            display: "inline-flex",
            alignItems: "center",
            fontSize: 20,
            marginRight: 10,
            fontWeight: "bold",
            border: "1px solid #000",
            padding: 10,
          }}
        >{`TOTAL DEORDENES: ${getRows().length}`}</Typography> */}
        <ShowValueTextField value={getRows().length} />
        <ReportTable rows={getRows()} />
      </>
    );
  };

  return (
    <div title="Reporte" cssClass="page-container">
      <div className="container-body">
        {isLoading ? <CircularProcess color="secondary" /> : renderView()}
      </div>
    </div>
  );
};

export default ReportView;
