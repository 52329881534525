/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import Expandable from "../Design/Expandable";
import DataTable from "../Design/DataTable";
import Modal from "../Design/Modal";
import OrderMap from "./OrderMap";
import { orderStatus } from "../../constants/constants";
import isEmpty from "lodash/isEmpty";
import moment from "moment";

const OrdersResume = ({
  branch,
  orders,
  title,
  cssClass,
  headerCssClass,
  Icon,
  useTime = true,
  showTime = false,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currOrder, setCurrOrder] = useState();
  // const ordersWithTime = useSelector(getOrdersWithTime);
  useEffect(() => {
    if (!isEmpty(currOrder) && !isEmpty(orders)) {
      setCurrOrder(orders.find((order) => order.id === currOrder.id));
    }
  }, [orders]);

  const onModalClose = () => {
    setIsModalOpen(false);
  };

  const getHeaders = () => {
    let headers = [
      {
        id: "orderId",
        name: "orden",
        style: {
          width: "20%",
        },
      },
      {
        id: "aliasName",
        name: "MOTORISTA",
        style: {
          width: "20%",
        },
      },
    ];
    if (useTime) {
      headers = [
        ...headers,
        {
          id: "arriveAt",
          name: "ARRIBO APROXIMADO",
          style: {
            width: "25%",
          },
        },
      ];
    } else {
      headers = [
        ...headers,
        {
          id: "orderStatus",
          name: "estado",
          style: {
            width: "25%",
          },
        },
      ];
    }
    if (showTime) {
      headers = [
        ...headers,
        {
          id: "arriveAt",
          name: "Llega En",
          style: {
            width: "25%",
          },
        },
      ];
    }
    headers = [
      ...headers,
      {
        id: "lookForOrder",
        name: "",
        style: {
          width: "15%",
        },
      },
    ];
    return headers;
  };

  const getArrivalTime = (time) => {
    if (time === "Calculando" || time === "En Tienda") {
      return time;
    }
    return moment(new Date(time)).format("DD-MM-YYYY HH:mm:ss");
  };

  return (
    <div>
      {isModalOpen && currOrder && (
        <Modal
          isOpened={isModalOpen}
          onClose={onModalClose}
          body={
            <OrderMap
              onModalClose={onModalClose}
              order={currOrder}
              branch={branch}
            />
          }
        />
      )}
      <Expandable
        headerCssClass={headerCssClass}
        title={
          <React.Fragment>
            <Typography variant="h6">
              <strong className="page-title">{title}</strong>
            </Typography>
          </React.Fragment>
        }
        defaultExpanded
      >
        <div className={cssClass}>
          <DataTable
            align="left"
            headers={getHeaders()}
            rows={(orders || []).map((order) => {
              let lookForOrder;
              if (
                order.isActive &&
                !isEmpty(order.alias) &&
                !isEmpty(order.alias.actualPosition) &&
                ((order.status === orderStatus.ACCEPTED && !isEmpty(branch)) ||
                  (order.status === orderStatus.GOING &&
                    !isEmpty(order.locationRef)))
              ) {
                lookForOrder = (
                  <div className="company-branches-links">
                    <div
                      onClick={(e) => {
                        setCurrOrder(order);
                        setIsModalOpen(true);
                      }}
                    >
                      Rastrear
                    </div>
                  </div>
                );
              }
              let customOrderStatus;
              if (order.status === orderStatus.ASSIGNED) {
                customOrderStatus = "EN CAMINO";
              } else if (order.status === orderStatus.ACCEPTED) {
                customOrderStatus = "EN CAMINO";
              } else if (order.status === orderStatus.RECEIVING) {
                customOrderStatus = "EN SUCURSAL";
              } else if (order.status === orderStatus.ACCEPTED) {
                customOrderStatus = "EN CAMINO";
              } else {
                customOrderStatus = order.status;
              }
              return {
                ...order,
                time: !order.isActive ? "" : order.time,
                arriveAt:
                  ((order.status === orderStatus.GOING && order.isActive) ||
                    order.status === orderStatus.ACCEPTED || order.status === orderStatus.ASSIGNED) &&
                  order.time !== undefined
                    ? getArrivalTime(order.time)
                    : "",
                orderStatus: customOrderStatus,
                circle: order.circle !== undefined ? order.circle : "Esperando",
                lookForOrder,
                aliasName: (order.alias || {}).name,
              };
            })}
          />
        </div>
      </Expandable>
    </div>
  );
};

export default OrdersResume;
